import React from "react";

const Nav = () => {
	return (
		<nav className="mainheader">
			<div className="d-flex align-items-center justify-content-end">
				<i className="fas fa-user"></i>
				<p className="fs-12 mb-0 ms-2">farhan@troontechnologies.com</p>
			</div>
		</nav>
	);
};

export default Nav;
