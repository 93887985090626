import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import AuthContext from "../../store/Auth/AuthContext";
import Logo from "./Logo";

const Sidebar = () => {
  const { logoutUser, } = useContext(AuthContext);
  let history = useHistory();
  const handleSettings = () => {
	  history.push("/settings",{propsState:"hello"})
  }

  return (
    <aside className="mainsidebar">
      <Logo />
      <div className="scroller">
        <ul className="navlist">
          <li>
            <NavLink to="/products">Products</NavLink>
          </li>
          <li>
            <NavLink to="/purchase-history">Purchase History</NavLink>
          </li>
          <li>
            <NavLink
			to="/settings"
             onClick={handleSettings}
            >
              Settings
            </NavLink>
          </li>
          <li>
            <NavLink to="/" onClick={logoutUser}>
              Logout
            </NavLink>
          </li>
        </ul>
      </div>
    </aside>
  );
};

export default Sidebar;
