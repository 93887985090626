import React, { useContext, useEffect, useState } from "react";
import {
	Container,
	Row,
	Col,
	Form,
	FormControl,
	Table,
	InputGroup,
} from "react-bootstrap";
import Nav from "../Layouts/Nav";
import Search from "../../assets/images/search.svg";
import Sidebar from "../Layouts/Sidebar";
import ApiContext from "../../store/Apis/ApiContext";
import moment from "moment";

const PurchaseHistory = () => {
	const { getProductsPurchaseHistory, purchaseHistory } =
		useContext(ApiContext);
		
	useEffect(() => {
		getProductsPurchaseHistory();
		// eslint-disable-next-line
	},[]);

	const [searchInput, setSearchInput] = useState("");
	const [filteredResults, setFilteredResults] = useState([]);

	const searchItems = (searchValue) => {
		setSearchInput(searchValue);
		// console.log({ searchInput });
		if (searchInput !== "") {
			const filteredPurchaseHistory = purchaseHistory.filter((item) => {
				// return Object.values(item)
				// 	.join("")
				// 	.toLowerCase()
				// 	.includes(searchInput.toLowerCase());
				return item.username.toLowerCase().includes(searchInput.toLowerCase());
			});
			setFilteredResults(filteredPurchaseHistory);
		} else {
			setFilteredResults(purchaseHistory);
		}
		console.log({ filteredResults });
	};

	// console.log({ purchaseHistory });

	return (
		<section className="maindashboard">
			<Nav />
			<Sidebar />
			<div className="contentwrapper">
				<div className="contentheader">
					<Container fluid>
						<Row>
							<Col md={12}>
								<div className="mainheading">
									<h1>Purchase History</h1>
								</div>
							</Col>
						</Row>
						<Row className="align-items-end">
							<Col md={6}>
								<Form.Label
									htmlFor="inlineFormInputGroupUsername"
									className="clr-black fs-12 text-uppercase">
									Search Product History
								</Form.Label>
								<InputGroup
									className="search"
									onKeyUp={(e) => searchItems(e.target.value)}>
									<InputGroup.Text>
										<img src={Search} className="img-fluid" alt="search" />
									</InputGroup.Text>
									<FormControl />
								</InputGroup>
							</Col>
						</Row>
					</Container>
				</div>
				<div className="content">
					<Container fluid>
						<Row>
							<Col md={12}>
								<Table>
									<thead>
										<tr>
											<th>Product Name</th>
											<th>User Name</th>
											<th className="ta-center">Token Per Unit</th>
											<th className="ta-center">Total Token</th>
											<th className="ta-center">Units Purchased</th>
											<th>TRX_ID</th>
											<th>Date &amp; Time</th>
										</tr>
									</thead>
									{purchaseHistory?.length > 0 ? (
									<tbody>
										{searchInput.length > 1
											? filteredResults.map((item) => {
													return (
														<tr key={item._id}>
															<td>{item.product_name}</td>
															<td>{item.username}</td>
															<td className="ta-center">{item.tokenPerUnit}</td>
															<td className="ta-center">
																{item.tokenPerUnit * item.unitPurchased}
															</td>
															<td className="ta-center">
																{item.unitPurchased}
															</td>
															<td>
																{item.trx_id ? (
																	<a
																		href={`https://rinkeby.etherscan.io/tx/${item.trx_id}`}
																		target="_blank"
																		rel="noreferrer">
																		{item?.trx_id?.substring(0, 4) +
																			"..." +
																			item?.trx_id?.substring(
																				item.trx_id.length - 4,
																				item.trx_id.length
																			)}
																	</a>
																) : (
																	"n/a"
																)}
															</td>
															<td>{item.timedate || "n/a"}</td>
														</tr>
													);
											  })
											: purchaseHistory.map((item, index) => {
													return (
														<tr key={item._id}>
															<td>{item.product_name}</td>
															<td>{item.username}</td>
															<td className="ta-center">{item.tokenPerUnit}</td>
															<td className="ta-center">
																{item.tokenPerUnit * item.unitPurchased}
															</td>
															<td className="ta-center">
																{item.unitPurchased}
															</td>
															<td>
																{item.trx_id ? (
																	<a
																		href={`https://rinkeby.etherscan.io/tx/${item.trx_id}`}
																		target="_blank"
																		rel="noreferrer">
																		{item?.trx_id?.substring(0, 4) +
																			"..." +
																			item?.trx_id?.substring(
																				item.trx_id.length - 4,
																				item.trx_id.length
																			)}
																	</a>
																) : (
																	"n/a"
																)}
															</td>
															<td>
																{moment(item.timedate).format(
																	" hh:mm a, DD MMM  YYYY"
																) || "n/a"}
															</td>
														</tr>
													);
											  })}

										{/* <tr>
											<td>Wetland Conservation in...</td>
											<td>Jaydon Lipshutz</td>
											<td>400</td>
											<td>400</td>
											<td>01</td>
											<td>
												<Link to="#">0xea...660e</Link>
											</td>
											<td>04:35 PM, 25 Oct 2021</td>
										</tr> */}
										{/* <tr>
											<td>Greenlaand Conservation...</td>
											<td>Kianna Rhiel Madsen</td>
											<td>400</td>
											<td>400</td>
											<td>01</td>
											<td>
												<Link to="#">0xea...660e</Link>
											</td>
											<td>04:35 PM, 25 Oct 2021</td>
										</tr> */}
									</tbody>
									) :(<tbody>
										<tr>
											<td>Records not found</td>
										</tr>
									</tbody>)}
								</Table>
							</Col>
						</Row>
					</Container>
				</div>
			</div>
		</section>
	);
};

export default PurchaseHistory;
