import React, { useContext, useEffect, useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import Nav from "../Layouts/Nav";
import Sidebar from "../Layouts/Sidebar";
import { Formik } from "formik";
import ApiContext from "../../store/Apis/ApiContext";
import { Editor } from "react-draft-wysiwyg";
import "./../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertToHTML } from "draft-convert";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";


const Settings = (props) => {
	const {
		getAgreements,
		agreements,
		addUpdateAgreement,
		success,
		term,
		offsetText,
		privacy,
	} = useContext(ApiContext);
	useEffect(() => {
		getAgreements();
		//eslint-disable-next-line
	}, [success]);
	const terms = localStorage.getItem("term");
	const privacys = localStorage.getItem("privacy");
	const offsets = localStorage.getItem("offset");

	const [editorStateTerm, setEditorStateTerm] = useState(
		EditorState.createWithContent(
			ContentState.createFromBlockArray(
				htmlToDraft(term ? term : terms).contentBlocks
			)
		)
	);
	const [editorStatePrivacy, setEditorStatePrivacy] = useState(
		EditorState.createWithContent(
			ContentState.createFromBlockArray(
				htmlToDraft(privacy ? privacy : privacys).contentBlocks
			)
		)
	);
	const [editorStateOffset, setEditorStateOffset] = useState(
		EditorState.createWithContent(
			ContentState.createFromBlockArray(
				htmlToDraft(offsetText ? offsetText : offsets).contentBlocks
			)
		)
	);

	const [convertedContentTerm, setConvertedContentTerm] = useState();
	const [convertedContentPrivacy, setconvertedContentPrivacy] = useState();
	const [convertedContentOffset, setconvertedContentOffset] = useState();

	const handleEditorChangeTerm = (state) => {
		setEditorStateTerm(state);
		let currentContentAsHTML = draftToHtml(
			convertToRaw(state.getCurrentContent())
		);
		console.log({ currentContentAsHTML });
		setConvertedContentTerm(currentContentAsHTML);
	};

	const handleEditorChangePrivacy = (state) => {
		setEditorStatePrivacy(state);
		let currentContentAsHTML = convertToHTML(state.getCurrentContent());
		setconvertedContentPrivacy(currentContentAsHTML);
	};

	const handleEditorChangeOffset = (state) => {
		setEditorStateOffset(state);
		let currentContentAsHTML = convertToHTML(state.getCurrentContent());
		setconvertedContentOffset(currentContentAsHTML);
	};

	// console.log("agreements", agreements, success);
	const handleAgreementSave = (term, privacy, offset) => {
		addUpdateAgreement(term, privacy, offset);
	};

	const handleReset = () => {
		setEditorStateTerm(EditorState.createEmpty());
		setEditorStatePrivacy(EditorState.createEmpty());
		setEditorStateOffset(EditorState.createEmpty());
		setConvertedContentTerm("");
		setconvertedContentPrivacy("");
		setconvertedContentOffset("");
	};

	return (
		<section className="maindashboard">
			<Nav />
			<Sidebar />
			<div className="contentwrapper">
				<div className="contentheader">
					<Container fluid>
						<Row>
							<Col md={12}>
								<div className="mainheading">
									<h1>Settings</h1>
								</div>
							</Col>
						</Row>
					</Container>
				</div>
				{<>

				
				{success ? (
					<div className="content mt-0">
						<Formik
							onSubmit={(values) => {
								// alert(JSON.stringify(values, null, 2));
							}}
							initialValues={{
								terms: agreements[0]?.termNCondition,
								privacy: agreements[0]?.privacy,
								offsettxt: agreements[0]?.offsetScreenText,
							}}>
							{({ handleSubmit, handleChange, resetForm, values }) => (
								<Form className="formstyle" onSubmit={handleSubmit}>
									<Container fluid>
										<Row>
											<Col md={12} lg={8}>
												<Form.Group
													className="mb-4"
													controlId="exampleForm.ControlTextarea1">
													<Form.Label className="text-uppercase">
														term and conditions
													</Form.Label>

													<Editor
														name="terms"
														editorState={editorStateTerm}
														onEditorStateChange={handleEditorChangeTerm}
													/>

													{/* <Form.Control
							as="textarea"
							rows={5}
							name="terms"
							onChange={handleChange}
							value={values.terms}
							/> */}
												</Form.Group>
											</Col>
										</Row>
										<Row>
											<Col md={12} lg={8}>
												<Form.Group
													className="mb-4"
													controlId="exampleForm.ControlTextarea1">
													<Form.Label className="text-uppercase">
														Privacy policy
													</Form.Label>
													<Editor
														name="privacy"
														editorState={editorStatePrivacy}
														onEditorStateChange={handleEditorChangePrivacy}
													/>
													{/* <Form.Control
                            as="textarea"
                            rows={5}
                            name="terms"
                            onChange={handleChange}
                            value={values.terms}
                          /> */}
												</Form.Group>
												{/* <Form.Group
                          className="mb-4"
                          controlId="exampleForm.ControlTextarea1"
                        >
                          <Form.Label className="text-uppercase">
                            Privacy policy
                          </Form.Label>
                          <Form.Control
                            as="textarea"
                            rows={5}
                            name="privacy"
                            onChange={handleChange}
                            value={values.privacy}
                          />
                        </Form.Group> */}
												<Form.Group
													className="mb-4"
													controlId="exampleForm.ControlTextarea1">
													<Form.Label className="text-uppercase" display="flex">
														Offset screen text {"  "}{" "}
														<Form.Label
															htmlFor="inlineFormInputGroupUsername"
															className="clr-grey text-lowercase fs-12  ms-1 ">
															{" "}
															(one liner text recommended)
														</Form.Label>
													</Form.Label>
													<Editor
														name="offsettxt"
														editorState={editorStateOffset}
														onEditorStateChange={handleEditorChangeOffset}
													/>
													{/* <Form.Control
                            as="textarea"
                            rows={5}
                            name="offsettxt"
                            onChange={handleChange}
                            value={values.offsettxt}
                          /> */}
												</Form.Group>
											</Col>
										</Row>
										<Row className="mt-4">
											<Col md="12" lg="5">
												<Button
													type="submit"
													className="btnprimary px-5 me-3"
													variant="dark"
													onClick={() =>
														handleAgreementSave(
															convertedContentTerm,
															convertedContentPrivacy,
															convertedContentOffset
														)
													}>
													Save
												</Button>
												<Button
													className="rounded-pill px-5"
													variant="light"
													type="reset"
													onClick={handleReset}>
													Reset
												</Button>
											</Col>
										</Row>
									</Container>
								</Form>
							)}
						</Formik>
					</div>
				) : (
					""
				)}
				</>
			}
			</div>
		</section>
	);
};

export default Settings;
