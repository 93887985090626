import React, { useContext} from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { Formik } from "formik";
import { addProductValidation } from "../../helpers/Validations";
import Nav from "../Layouts/Nav";
import Sidebar from "../Layouts/Sidebar";
import UploadComponent from "../../helpers/UploadComponent";
import { useHistory, useParams } from "react-router-dom";
import ApiContext from "../../store/Apis/ApiContext";

const AddProduct = () => {
	const history = useHistory();
	const { addProduct, updateProduct } = useContext(ApiContext);

	const { id } = useParams();
	const product = id !== undefined ? history?.location?.state?.product : "";
	console.log({ id, product });
	

	return (
		<section className="maindashboard">
			<Nav />
			<Sidebar />
			<div className="contentwrapper">
				<div className="contentheader">
					<Container fluid>
						<Row>
							<Col md={12}>
								<div className="mainheading d-flex align-items-center">
									<i
										onClick={() => history.push("/products")}
										className="fas fa-long-arrow-alt-left fa-2x me-4 pointer"></i>
									<h1 className="mb-0">
										{!id ? "Add" : "Edit"} product details
									</h1>
								</div>
							</Col>
						</Row>
					</Container>
				</div>
				<div className="content">
					<Container fluid>
						<Row>
							<Col md={12}>
								<Formik
									validationSchema={addProductValidation}
									onSubmit={(values) => {
										// salert(JSON.stringify(values, null, 2));
										// console.log("data file", values.files);
										// console.log({ values });

										id === undefined
											? addProduct(values)
											: updateProduct(values, id);
									}}
									initialValues={{
										pname: product[0]?.name || "",
										ptitle: product[0]?.title || "",
										tokenperunit: product[0]?.tokenPerTon || "",
										descc: product[0]?.description || "",
										switch: product[0]?.active || false,
										files: product[0]?.imagepath || "",
									}}>
									{({
										handleSubmit,
										handleChange,
										values,
										setFieldValue,
										errors,
										resetForm,
									}) => (
										<Form
											className="formstyle"
											noValidate
											onSubmit={handleSubmit}>
											<Row className="">
												<Col md={6} lg={3}>
													<Form.Group className="mb-3" controlId="pname">
														<Form.Label className="text-uppercase">
															product name*
														</Form.Label>
														<Form.Control
															className="input"
															type="text"
															name="pname"
															onChange={handleChange}
															value={values.pname}
														/>
														<div className="text-danger">
															<p className="fs-12">{errors.pname}</p>
														</div>
													</Form.Group>
												</Col>
												<Col md={6} lg={3}>
													<Form.Group className="" controlId="ptitle">
														<Form.Label className="text-uppercase">
															Product title
														</Form.Label>
														<Form.Control
															className="input"
															type="text"
															name="ptitle"
															isInvalid={!!errors.ptitle}
															onChange={handleChange}
															value={values.ptitle}
														/>
													</Form.Group>
													<div className="text-danger">
														<p className="fs-12">{errors.ptitle}</p>
													</div>
												</Col>
												<Col md={6} lg={3}>
													<Form.Group className="" controlId="tokenperunit">
														<Form.Label className="text-uppercase">
															Token per unit*
														</Form.Label>
														<Form.Control
															className="input"
															type="text"
															name="tokenperunit"
															isInvalid={!!errors.tokenperunit}
															onChange={handleChange}
															value={values.tokenperunit}
														/>
													</Form.Group>
													<div className="text-danger">
														<p className="fs-12">{errors.tokenperunit}</p>
													</div>
												</Col>
												<Col className="mb-md-0 mb-3">
													<Form.Group className="" controlId="tokenperunit">
														<Form.Label className="text-uppercase d-none d-md-inline-block"></Form.Label>
														<div
															style={{
																display: "flex",
																alignItems: "center",
																marginTop: "14px",
															}}>
															<Form.Check
																type="switch"
																id="custom-switch"
																variant="dark"
																name="switch"
																className=""
																onChange={handleChange}
																// value={values.switch}
																checked={values.switch}
															/>
															<Form.Label
																className="text-uppercase  mr-18 mb-0"
																style={{ marginTop: "5px" }}>
																{values.switch ? "Active" : "InActive"}
															</Form.Label>
														</div>
													</Form.Group>
												</Col>
												<Col md={10} className="mt-3">
													<Form.Group className="" controlId="descc">
														<Form.Label
															className="text-uppercase"
															style={{ display: "flex" }}>
															Description {"    "}
															<Form.Label
																htmlFor="inlineFormInputGroupUsername"
																className="clr-grey text-lowercase fs-12 ms-1">
																{" "}
																(3 lines recommended)
															</Form.Label>
														</Form.Label>
														<Form.Control
															className="textarea"
															as="textarea"
															rows={5}
															name="descc"
															isInvalid={!!errors.descc}
															onChange={handleChange}
															value={values.descc}
														/>
													</Form.Group>
													<div className="text-danger">
														<p className="fs-12">{errors.descc}</p>
													</div>
												</Col>
												<Col md={5} className="mt-3">
													<Form.Group className="" controlId="descc">
														<Form.Label className="text-uppercase">
															Upload product image
														</Form.Label>
														<UploadComponent
															setFieldValue={setFieldValue}
															file={values?.files}
															name="files"
															// onChange={handleChangeFile}
															// isInvalid={!!errors.files}
															value={values?.files}
														/>
														<div className="text-danger">
															<p className="fs-12">{errors.files}</p>
														</div>
													</Form.Group>
												</Col>
											</Row>
											{/* dynamic button  */}

											<Row className="mt-4">
												<Col md="12" lg="5">
													<Button
														className="btnprimary px-5 me-3"
														variant="dark"
														type="submit">
														Submit
													</Button>

													{!id && (
														<Button
															type="reset"
															onClick={resetForm}
															className="rounded-pill px-5"
															variant="light">
															Reset
														</Button>
													)}
												</Col>
											</Row>
										</Form>
									)}
								</Formik>
							</Col>
						</Row>
					</Container>
				</div>
			</div>
		</section>
	);
};

export default AddProduct;
