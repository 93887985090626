import axios from "axios";

const axiosClient = axios.create();

axiosClient.defaults.baseURL = process.env.REACT_APP_APIPOINT;





axios.interceptors.request.use((req) => {
	const token = localStorage.getItem("token");
	if (token) {
		req.headers["x-access-token"] = token;
		return req;
	} else {
		return req;
	}
});



export default axiosClient;
