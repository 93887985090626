import React, { useReducer, useContext } from "react";
import ApiReducer from "./ApiReducer";
import ApiContext from "./ApiContext";
import AuthContext from "../../store/Auth/AuthContext";
import {
	deleteRequest,
	getRequest,
	postRequest,
	putRequest,
} from "../../utils/AxiosMethods";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

const initialState = {
	agreements: [],
	products: [],
	purchaseHistory: [],
	isProductAdded: false,
	success: false,
	term: null,
	privacy: null,
	offsetText: null,
};

const ApiState = (props) => {
	const token = localStorage.getItem("token");
	const toastSuccess = (text) => toast.success(text);
	const toastError = (text) => toast.error(text);

	let axiosConfig = {
		headers: {
			"Content-Type": "application/json",
			"x-access-token": token,
		},
	};

	const history = useHistory();
	const [state, dispatch] = useReducer(ApiReducer, initialState);
	const {  logoutUser } = useContext(AuthContext);

	async function getAgreements() {
		try {
			const resp = await getRequest("agreements");
			if (resp.status === 200) {
				dispatch({
					type: "GET_AGREEMENTS",
					payload: resp.data,
				});
			} else {
				// console.log(resp);
			}
		} catch (error) {
			// console.log(error);
		}
	}

	async function getProducts() {
		try {
			const resp = await getRequest("products");
			// console.log(resp);
			if (resp.status === 200) {
				dispatch({
					type: "GET_PRODUCTS",
					payload: resp.data,
				});
			} else {
				// console.log(resp);
			}
		} catch (error) {
			console.log(error);
		}
	}

	// async function getProductByID(id) {
	// 	try {
	// 		const resp = await getRequest("products/" + id);
	// 		console.log(resp);
	// 		if (resp.status === 200) {
	// 			dispatch({
	// 				type: "GET_PRODUCTS_BY_ID",
	// 				payload: resp.data,
	// 			});
	// 		} else {
	// 			console.log(resp);
	// 		}
	// 	} catch (error) {
	// 		console.log(error);
	// 	}
	// }

	async function deleteProduct(id) {
		try {
			const resp = await deleteRequest("product/" + id, axiosConfig);
			// console.log(resp);
			if (resp.status === 200) {
				getProducts();
				toastSuccess("Deleted Successfully!");
			} else {
				// console.log(resp);
			}
		} catch (error) {
			if (error.response.status === 401) {
				logoutUser();
			}
			// console.log(error);
		}
	}

	async function getProductsPurchaseHistory() {
		try {
			const resp = await getRequest("productshistory");
			// console.log(resp);
			if (resp.status === 200) {
				dispatch({
					type: "GET_PRODUCTS_PURCHASE_HISTORY",
					payload: resp.data,
				});
			} else {
				// console.log(resp);
			}
		} catch (error) {
			// console.log(error);
		}
	}

	const addUpdateAgreement = async (term, privacy, offset) => {
		const termsData = {
			offsetScreenText: offset,
			privacy: privacy,
			termNCondition: term,
		};
		// console.log("term", termsData);

		try {
			const resp = await postRequest("agreements", termsData, axiosConfig);
			// console.log(resp);
			if (resp.status === 200) {
				getAgreements();
				toastSuccess("Updated Successfully!");
			} else {
				// console.log({ resp });
			}
		} catch (error) {
			if (error.response.status === 401) {
				logoutUser();
			}
			if (error.response.status === 400) {
				toastError("Fields cannot be empty!");
			}
			// console.log({ error });
		}
	};

	async function addProduct(data) {
		var formData = new FormData();

		formData.append("name", data.pname);
		formData.append("description", data.descc);
		formData.append("file", data.files);
		formData.append("active", data.switch);
		formData.append("tokenPerTon", parseInt(data.tokenperunit));
		formData.append("title", data.ptitle);
		// console.log("formdata ", formData);

		try {
			const resp = await postRequest("products", formData, axiosConfig);
			if (resp.status === 201) {
				dispatch({
					type: "ADD_PRODUCT",
				});
				toastSuccess("Added Successfully!");
				history.push("/products");
			} else {
				// console.log(resp);
			}
		} catch (error) {
			if (error.response.status === 401) {
				logoutUser();
			}
		}
	}

	async function updateProduct(data, id) {
		// console.log({ id, data });
		var formData = new FormData();

		formData.append("name", data.pname);
		formData.append("description", data.descc.trim());
		formData.append("file", data.files);
		formData.append("active", data.switch);
		formData.append("tokenPerTon", parseInt(data.tokenperunit));
		formData.append("title", data.ptitle);
		// console.log("formdata ", formData);

		try {
			const resp = await putRequest(`product/${id}`, formData, axiosConfig);
			// console.log("Update product response", resp);
			if (resp.status === 200) {
				history.push("/products");
				toastSuccess("Updated Successfully!");
			} else {
				// console.log(resp);
			}
		} catch (error) {
			// console.log("error", error);
			if (error.response.status === 401) {
				logoutUser();
			}
		}
	}

	return (
		<ApiContext.Provider
			value={{
				getAgreements,
				agreements: state.agreements,
				getProducts,
				deleteProduct,
				products: state.products,
				purchaseHistory: state.purchaseHistory,
				getProductsPurchaseHistory,
				addProduct,
				updateProduct,
				isProductAdded: state.isProductAdded,
				success: state.success,
				term: state.term,
				privacy: state.privacy,
				offsetText: state.offsetText,
				addUpdateAgreement,
			}}>
			{props.children}
		</ApiContext.Provider>
	);
};
export default ApiState;
