
const ApiReducer = (state, action) => {
	switch (action.type) {
		case "GET_AGREEMENTS":
			localStorage.setItem("term", action?.payload?.data[0]?.termNCondition || <p>terms and conditions</p>);
			localStorage.setItem("privacy", action?.payload?.data[0]?.privacy || <p> privacy policy</p>);
			localStorage.setItem("offset", action?.payload?.data[0]?.offsetScreenText || <p> offset text</p>);
			return {
				...state, 
				agreements: action?.payload?.data,
				term: action?.payload?.data[0]?.termNCondition,
				privacy: action?.payload?.data[0]?.privacy,
				offsetText: action?.payload?.data[0]?.offsetScreenText,
				success: true,
				notify: true,
			};
		case "GET_PRODUCTS":
			return {
				...state,
				products: action?.payload?.data,
			};
		case "GET_PRODUCTS_PURCHASE_HISTORY":
			return {
				...state,
				purchaseHistory: action?.payload?.data,
			};
		case "ADD_PRODUCT":
			return {
				...state,
				isProductAdded: true,
			};

		default: {
			return state;
		}
	}
};

export default ApiReducer;
