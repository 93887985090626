import * as Yup from "yup";

export const loginValidation = Yup.object().shape({
  email: Yup.string().required("Email Address Requried"),
  password: Yup.string().required("Password Required"),

});

export const addProductValidation = Yup.object().shape({
  pname: Yup.string().required("Product Name Requried"),
  ptitle: Yup.string().required("Product Title Required"),
  tokenperunit: Yup.number("number").integer('Enter a number without decimal value').typeError(' Number Required').positive().required("Token Required"),
  descc: Yup.string().required("Description Required").matches(/^[^\s]+(\s+[^\s]+)*/, "characters must be alphanumaric"),
  files: Yup.string().required("Image Required"),

});
