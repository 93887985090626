import React from "react";
import { Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/scss/screen.css";
import Products from "./components/Products";
import PurchaseHistory from "./components/PurchaseHistory";
import Settings from "./components/Settings";
import Login from "./components/Login";
import AddProduct from "./components/AddProduct";
import PrivateRoute from "./components/PrivateRoutes";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

const App = () => {
  return (
    <>
      <PrivateRoute
        exact
        path="/products"
        component={Products}
        activeClassName="is-active"
      />
      <PrivateRoute exact path="/add-product" component={AddProduct} />
      <PrivateRoute exact path="/edit-product/:id" component={AddProduct} />
      <PrivateRoute
        exact
        path="/purchase-history"
        component={PurchaseHistory}
        activeClassName="is-active"
      />
      <PrivateRoute
        exact
        path="/settings"
        component={Settings}
        activeClassName="is-active"
      />
      <Route exact path="/">
        <Login />
      </Route>
      {/* <Redirect to={"/"} /> */}
      {/* <button onClick={notify}>Notify !</button> */}
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default App;
