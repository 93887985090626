import React, { useState } from "react";
import { useDropzone } from "react-dropzone";

const UploadComponent = (props) => {
	const [files, setFiles] = useState([]);
	const { setFieldValue, file } = props;
	const imageBaseUrl = process.env.REACT_APP_IMAGE_BASE_URL;
	const { getRootProps, getInputProps } = useDropzone({
		accept: "image/*",
		multiple: false,

		onDrop: (acceptedFiles) => {
			setFieldValue("files", acceptedFiles[0]);
			setFiles(
				acceptedFiles.map((file) =>
					Object.assign(file, {
						preview: URL.createObjectURL(file),
					})
				)
			);
		},
	});
	console.log("FILES", files.length);

	const Thumbs = () => {
		return (
			<>
				{file && (
					<aside className="thumbsContainer">
						<div className="thumb">
							<div className="thumbInner">
								{file ? (
									<img
										src={
											files[0] === undefined
												? imageBaseUrl + file
												: files[0]?.preview
										}
										className="img-fluid"
										alt="preview"
									/>
								) : (
									""
								)}
							</div>
						</div>
					</aside>
				)}
			</>
		);
	};

	return (
		<>
			<div {...getRootProps({ className: "dropzone" })}>
				<input {...getInputProps()} />
				<i className="fas fa-file-upload text-black"></i>
				<p>Drag file here to upload or Browse file</p>
				<p>Supported file types jpg,png,svg with transparent background</p>
				<p>File size must not exceed 20 MB</p>
			</div>
			<Thumbs />
		</>
	);
};

export default UploadComponent;
