import axiosClient from "./AxiosClient";

export function getRequest(URL) {
	return axiosClient.get(`/${URL}`).then((response) => response);
}

export function postRequest(URL, payload, axiosConfig) {
	return axiosClient
		.post(`/${URL}`, payload, axiosConfig)
		.then((response) => response);
}

export function putRequest(URL, payload, axiosConfig) {
	return axiosClient
		.put(`/${URL}`, payload, axiosConfig)
		.then((response) => response);
}

export function deleteRequest(URL, axiosConfig) {
	return axiosClient
		.delete(`/${URL}`, axiosConfig)
		.then((response) => response);
}
