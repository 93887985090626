import React, { useContext, useEffect } from "react";
import { Row, Col, Button, Form, Container } from "react-bootstrap";
import Logo from "../../assets/images/logo.svg";
import { Formik } from "formik";
import { loginValidation } from "../../helpers/Validations";
import AuthContext from "../../store/Auth/AuthContext";

import { useHistory } from "react-router-dom";

const Login = () => {
	const { loginUser,  isLoggedIn } = useContext(AuthContext);
	let history = useHistory();

	useEffect(() => {
		if (isLoggedIn) {
			history.push("/products");	
		}
		//eslint-disable-next-line
	}, [isLoggedIn]);

	return (
		<section className="login">
			<Container fluid className="p-0">
				<Row className="g-0">
					<Col md={5} className="loginLeftColumn">
						<div className="authleftside test">
							<h1 className="mb-3">Welcome to</h1>
							<img src={Logo} className="img-fluid" alt="logo" />
						</div>
					</Col>
					<Col md={7} className="position-relative">
						<div className="authrightside">
							<h1 className="mb-3">Sign In</h1>
							<p className="clr-grey mb-4">To the Admin account</p>

							<Formik
								validationSchema={loginValidation}
								onSubmit={(values) => {
									// alert(JSON.stringify(values, null, 2));
									loginUser(values);
								}}
								initialValues={{
									email: "",
									password: "",
								}}>
								{({ handleSubmit, handleChange, values, errors }) => (
									<Form
										className="formstyle"
										noValidate
										onSubmit={handleSubmit}>
										<Form.Group className="mb-3" controlId="email">
											<Form.Control
												className="input"
												type="email"
												placeholder="Enter Email"
												name="email"
												isInvalid={!!errors.email}
												onChange={handleChange}
											/>
											<div className="text-danger">
												<p className="fs-12">{errors.email}</p>
											</div>
										</Form.Group>

										<Form.Group className="" controlId="password">
											<Form.Control
												className="input"
												type="password"
												name="password"
												isInvalid={!!errors.password}
												onChange={handleChange}
												placeholder="Password"
											/>
											<div className="text-danger">
												<p className="fs-12">{errors.password}</p>
											</div>
										</Form.Group>

										<Form.Group className="mb-40 pointer" controlId="terms">
											<Form.Check
												type="checkbox"
												onChange={handleChange}
												name="remember"
												label="Remember me"
												className="remember-me"
											/>
										</Form.Group>
										<Button
											className="btnprimary d-block w-100"
											variant="dark"
											type="submit">
											Sign in
										</Button>
									</Form>
								)}
							</Formik>
						</div>
					</Col>
				</Row>
			</Container>
		</section>
	);
};

export default Login;
