
const AuthReducer = (state, action) => {
	switch (action.type) {
		case "LOGIN":
			// console.log("inside reducer func");
			localStorage.setItem("token", action.payload);
			return {
				...state,
				isLoggedIn: true,
				token: action.payload,
				loginError: "",
			};
		case "LOGIN_FAIL":
			// console.log("inside reducer login fail func");
			return {
				...state,
				isLoggedIn: false,
				token: null,
				loginError: action.payload.error,
			};
		case "LOGOUT":
			localStorage.removeItem("term");
			localStorage.removeItem("privacy");
			localStorage.removeItem("offset");
			localStorage.removeItem("token");
		
			return {
				...state,
				isLoggedIn: false,
				token: null,
				loginError: "",
			};
		default: {
			return state;
		}
	}
};

export default AuthReducer;
