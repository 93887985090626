import  { useReducer } from "react";
import { postRequest } from "../../utils/AxiosMethods";
import AuthContext from "./AuthContext";
import AuthReducer from "./AuthReducer";
import { toast } from "react-toastify";

const AuthState = (props) => {
	const token = localStorage.getItem("token");
	const toastSuccess = (text) => toast.success(text);
	const toastError = (text) => toast.error(text);

	const userState = token
		? {
				isLoggedIn: true,
				token: token,
				loginError: "",
		  }
		: {
				isLoggedIn: false,
				token: null,
				loginError: "",
		  };

	const [state, dispatch] = useReducer(AuthReducer, userState);

	const loginUser = async (data) => {
		// console.log({ data });
		try {
			const resp = await postRequest("login", data);
			// console.log({ resp });
			if (resp.status === 200) {
				dispatch({
					type: "LOGIN",
					payload: resp.data,
				});
				toastSuccess("Logged In Successfully");
			} else {
				// console.log("inside else");
				// console.log({ resp });
			}
		} catch (error) {
			// console.log("error", error.response.data);
			dispatch({
				type: "LOGIN_FAIL",
				payload: error.response.data,
			});
			toastError(error.response.data.error);
		}
	};

	const logoutUser = async () => {
		dispatch({
			type: "LOGOUT",
		});
	};

	return (
		<AuthContext.Provider
			value={{
				loginUser,
				logoutUser,
				isLoggedIn: state.isLoggedIn,
				token: state.token,
				loginError: state.loginError,
			}}>
			{props.children}
		</AuthContext.Provider>
	);
};

export default AuthState;
