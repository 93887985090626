import React, { useContext, useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormControl,
  Table,
  Button,
  InputGroup,
  Dropdown,
} from "react-bootstrap";
import Nav from "../Layouts/Nav";
import Search from "../../assets/images/search.svg";
import Sidebar from "../Layouts/Sidebar";
import ApiContext from "../../store/Apis/ApiContext";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const Products = () => {
  const history = useHistory();
  const { getProducts, products, deleteProduct, getAgreements } =
    useContext(ApiContext);

  useEffect(() => {
    getAgreements();
	// eslint-disable-next-line
  },[]);

  const [searchInput, setSearchInput] = useState("");
  const [filteredResults, setFilteredResults] = useState([]);

  const searchItems = (searchValue) => {
    setSearchInput(searchValue);
    if (searchInput !== "") {
      const filteredProducts = products.filter((item) => {
        // return Object.values(item)
        // 	.join("")
        // 	.toLowerCase()
        // 	.includes(searchInput.toLowerCase());
        return item.name.toLowerCase().includes(searchInput.toLowerCase());
      });
      setFilteredResults(filteredProducts);
    } else {
      setFilteredResults(products);
    }
    console.log({ filteredResults });
  };

  useEffect(() => {
    getProducts();
	// eslint-disable-next-line
  },[]);

  const handleDelete = (id) => {
    deleteProduct(id);
  };

  const addProductHandler = (event) => {
    event.preventDefault();
    history.push("/add-product");
  };

  const getProductHandler = (id) => {
    return products.filter((product) => product._id === id);
  };

  const editProductHandler = (id) => {
    history.push(`/edit-product/${id}`, { product: getProductHandler(id) });
  };

  // Local
  // const imageBaseUrl = "http://localhost:8000/uploads/1639999139964.jpg";

  // Production
  const imageBaseUrl = process.env.REACT_APP_IMAGE_BASE_URL;

  return (
    <section className="maindashboard">
      <Nav />
      <Sidebar />
      <div className="contentwrapper">
        <div className="contentheader">
          <Container fluid>
            <Row>
              <Col md={12}>
                <div className="mainheading">
                  <h1>Products</h1>
                </div>
              </Col>
            </Row>
            <Row className="align-items-end">
              <Col lg={6} md={6} className="mb-md-0 mb-3">
                <Form.Label
                  htmlFor="inlineFormInputGroupUsername"
                  className="clr-black fs-12 text-uppercase"
                >
                  Search Products
                </Form.Label>
                <InputGroup
                  className="search"
                  onChange={(e) => searchItems(e.target.value)}
                >
                  <InputGroup.Text>
                    <img src={Search} className="img-fluid" alt="search" />
                  </InputGroup.Text>
                  <FormControl />
                </InputGroup>
              </Col>
              <Col lg={6} md={6} className="text-end">
                <Button
                  onClick={addProductHandler}
                  className="btnprimary px-5"
                  variant="dark"
                >
                  add product
                </Button>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="content">
          <Container fluid>
            <Row>
              <Col md={12}>
                <div className="tableresponsive">
                  <Table>
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Image</th>
                        <th>Product Name</th>
                        <th>Product Title</th>
                        <th>Description</th>
                        <th className="ta-center">Token Per Unit</th>
                        <th>Status</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                     {products?.length > 0 ? (
                    <tbody>
                      {searchInput.length >= 2
                        ? filteredResults.map((product, index) => {
                            return (
                              <tr key={product._id}>
                                <td title={product._id}>
                                  {index <= 9 ? "0" + (index + 1) : index + 1}
                                </td>
                                <td>
                                  <img
                                    // Production <<<<<<<<<<<
                                    src={
                                      imageBaseUrl + product.imagepath ||
                                      "https://via.placeholder.com/42"
                                    }
                                    // Local <<<<<<<<<<
                                    // src={
                                    // 	imageBaseUrl ||
                                    // 	"https://via.placeholder.com/42"
                                    // }
                                    className="img-fluid"
                                    style={{
                                      width: "42px",
                                      height: "42px",
                                      objectFit: "cover",
                                      borderRadius: "8px",
                                    }}
                                    alt="product"
                                  />
                                </td>
                                <td>{product.name}</td>
                                <td>{product.title}</td>
                                <td
                                  style={{
                                    maxWidth: "300px",
                                    textAlign: "justify",
                                  }}
                                >
                                  {product.description}
                                </td>
                                <td className="ta-center">
                                  {product.tokenPerTon}
                                </td>
                                <td>
                                  {product.active ? "Active" : "Inactive"}
                                </td>
                                <td className="text-center pointer">
                                  <Dropdown>
                                    <Dropdown.Toggle
                                      id="dropdown-basic"
                                      as="span"
                                    >
                                      <i className="fas fa-ellipsis-v"></i>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu align="end">
                                      <Dropdown.Item
                                        onClick={() =>
                                          editProductHandler(product._id)
                                        }
                                      >
                                        Edit
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        // href={`/edit-product/${product._id}`}
                                        onClick={() =>
                                          handleDelete(product._id)
                                        }
                                      >
                                        Delete
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </td>
                              </tr>
                            );
                          })
                        : products.map((product, index) => {
                            return (
                              <tr key={product._id}>
                                <td title={product._id}>
                                  {index <= 9 ? "0" + (index + 1) : index + 1}
                                </td>
                                <td>
                                  <img
                                    // Production <<<<<<<<<<<
                                    src={
                                      imageBaseUrl + product.imagepath ||
                                      "https://via.placeholder.com/42"
                                    }
                                    // Local <<<<<<<<<<
                                    // src={
                                    // 	imageBaseUrl ||
                                    // 	"https://via.placeholder.com/42"
                                    // }
                                    className="img-fluid"
                                    style={{
                                      width: "42px",
                                      height: "42px",
                                      objectFit: "cover",
                                      borderRadius: "8px",
                                    }}
                                    alt="product"
                                  />
                                </td>
                                <td>{product.name}</td>
                                <td>{product.title}</td>
                                <td
                                  style={{
                                    maxWidth: "300px",
                                    textAlign: "justify",
                                    wordBreak: "break-word",
                                  }}
                                >
                                  {product.description}
                                </td>
                                <td className="ta-center">
                                  {product.tokenPerTon}
                                </td>
                                <td>
                                  {product.active ? "Active" : "Inactive"}
                                </td>
                                <td className="text-center pointer">
                                  <Dropdown>
                                    <Dropdown.Toggle
                                      id="dropdown-basic"
                                      as="span"
                                    >
                                      <i className="fas fa-ellipsis-v"></i>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu align="end">
                                      <Dropdown.Item
                                        onClick={() =>
                                          editProductHandler(product._id)
                                        }
                                      >
                                        Edit
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        // href={`/edit-product/${product._id}`}
                                        onClick={() =>
                                          handleDelete(product._id)
                                        }
                                      >
                                        Delete
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </td>
                              </tr>
                            );
                          })}
                      {/* <tr>
												<td>01</td>
												<td>
													<img
														src="https://via.placeholder.com/42"
														alt="impactscope"
													/>
												</td>
												<td>Wetland Conservation...</td>
												<td>Conservation in Indon... </td>
												<td>Lorem ipsum dolor...</td>
												<td>400</td>
												<td>Active</td>
												<td className="text-center pointer">
													<Dropdown>
														<Dropdown.Toggle id="dropdown-basic" as="span">
															<i className="fas fa-ellipsis-v"></i>
														</Dropdown.Toggle>
														<Dropdown.Menu align="end">
															<Dropdown.Item href="/edit-product/1">
																Edit
															</Dropdown.Item>
															<Dropdown.Item href="/edit-product/1">
																Delete
															</Dropdown.Item>
														</Dropdown.Menu>
													</Dropdown>
												</td>
											</tr> */}
                    </tbody>
					) : <>
					<tbody>
						<tr>
							<td>Record not found</td>
						</tr>
					</tbody>
					</>}
                  </Table>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </section>
  );
};

export default Products;
