import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import AuthContext from "../../store/Auth/AuthContext";
function PrivateRoute({ component: Component, ...rest }) {
	const { isLoggedIn } = useContext(AuthContext);
	return (
		<Route
			{...rest}
			render={(props) =>
				isLoggedIn ? <Component {...props}></Component> : <Redirect to="/" />
			}></Route>
	);
}

export default PrivateRoute;
