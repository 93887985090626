import React from "react";
import Logowhite from "../../assets/images/logowhite.svg";
import { Link } from "react-router-dom";

const Logo = () => {
  return (
    <Link to="/" className="brand-link">
      <img src={Logowhite} alt="ImpactScope Logo" />
    </Link>
  );
};

export default Logo;
