import axios from "axios";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import ApiState from "./store/Apis/ApiState";
import { BrowserRouter as Router,  Switch } from "react-router-dom";
import AuthState from "./store/Auth/AuthState";

axios.interceptors.request.use((req) => {
	// console.log({ req });
	// if (user && user.auth_token) {
	//   req.headers.Authorization = "Bearer " + user.auth_token;
	//   return req;
	// } else {
	//   return req;
	// }
});

ReactDOM.render(
	<React.StrictMode>
		<Router>
			<Switch>
				<AuthState>
					<ApiState>
						<App />
					</ApiState>
				</AuthState>
			</Switch>
		</Router>
	</React.StrictMode>,
	document.getElementById("root")
);
